import { createBrowserRouter } from "react-router-dom";
import ViewLawyers from "./ViewLawyers";
import EditLawyer from "./EditLawyer";
import axios from "axios";
import ViewLawyer from "./ViewLawyer";
import AddHuissier from "./AddHuissier";
import AddNotaire from "./AddNotaires";
import AddLawyer from "./AddLawyer";
import AddLawText from "./AddLawText";

axios.defaults.baseURL = "https://app-api.barreaucameroun.org/";
// axios.defaults.baseURL = "http://localhost:8000/";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.headers.post["Accept"] = "application/json";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ViewLawyers />,
  },
  {
    path: "/view-lawyer/:id",
    element: <ViewLawyer />,
  },
  {
    path: "/edit-lawyer/:id",
    element: <EditLawyer />,
  },
  {
    path: "/add-huissier",
    element: <AddHuissier />,
  },
  {
    path: "/add-notaire",
    element: <AddNotaire />,
  },
  {
    path: "/add-lawyer",
    element: <AddLawyer />,
  },
  {
    path: "/add-lawText",
    element: <AddLawText />,
  },
]);

export default router;
